import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade' // see https://www.react-reveal.com/examples/

import Layout from '../components/Layout'

const Header = styled.h1`
`

const Subheader = styled.h2`
`

const Text = styled.p`
`

const Button = styled.a.attrs({
  target: '_blank'
})`
`

const EasyStarter = ({ data: { markdownRemark: { frontmatter } } }) =>
  <Layout frontmatter={frontmatter}>
    <Fade bottom ssrReveal>
      <div>
        <Header>{frontmatter.header}</Header>
        <Subheader>{frontmatter.subheader}</Subheader>
        <Text>{frontmatter.text}</Text>
        <Button href={frontmatter.link}>{frontmatter.buttonText}</Button>
      </div>
    </Fade>
  </Layout>

export default EasyStarter

export const pageQuery = graphql`
  query EasyStarterBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        templateKey
        title
        description
        header
        subheader
        text
        buttonText
        link
        favicon {
          publicURL
        }
      }
    }
  }
`
